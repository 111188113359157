<template>
  <v-card
    id="cancellation-reason-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <cancellation-reason-tab-general
          :data-params="saveData"
          :tab="tab"
          :option="option"
          :facilities="facilities"
          @changeTab="changeTab"
          @save="onSave"
        ></cancellation-reason-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import {
  onMounted, ref, watch, getCurrentInstance,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import {
  getFacilities,
  createCancellationReason,
  editCancellationReason,
  getCancellationReason,
} from '@api'
import Swal from 'sweetalert2'

// demos
import CancellationReasonTabGeneral from './CancellationReasonTabGeneral.vue'

export default {
  components: {
    CancellationReasonTabGeneral,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { t, tInContext } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const option = ref(1)
    const dataId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const saveData = ref({
      name: null,
      description: null,
      status: 'A',
      group_id: null,
    })
    const postData = ref({
      name: null,
      description: null,
      status: 'A',
      group_id: null,
    })
    const putData = ref({
      name: null,
      description: null,
      status: 'A',
      group_id: null,
    })
    const facilities = ref([])

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const goBack = () => {
      router.replace({
        name: 'views-organization-settings-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const onSave = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          name: data.name,
          description: data.description,
          status: data.status,
          group_id: configOrganization.value,
        })

        resp = await createCancellationReason(postData.value)

        console.log('🚀 ~ onSave ~ resp:', resp)

        if (resp.ok) {
          goBack()
          success(resp.message)
        } else {
          error(resp.message.text)
        }
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          description: data.description,
          status: data.status,
          group_id: configOrganization.value,
        })

        Swal.fire({
          title: tInContext('questions.are_you_sure', vm),
          html: `<span style="padding-inline: 20px;">${tInContext('questions.reason_by_modified', vm)}</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: `${tInContext('questions.yes', vm)} ${tInContext('tooltip.update', vm)}!`,
          cancelButtonText: tInContext('questions.no', vm),
        }).then(async result => {
          if (result.isConfirmed) {
            resp = await editCancellationReason(putData.value, data.id)

            console.log('🚀 ~ onSave ~ resp:', resp)
            if (resp.ok) {
              goBack()
              success(resp.message)
            } else {
              error(resp.message.text)
            }
          }
        })
      }

      if (!option.value) {
        goBack()
      }
    }

    const onCancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      saveData.value = {}
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      dataId.value = route.value.params.id

      if (!option.value) goBack()

      if (option.value && dataId.value) {
        const resp = await getCancellationReason(dataId.value)
        if (resp.ok) {
          saveData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value !== 1) {
        tabs.value.splice(1, 1)
      }

      const response = await getFacilities()
      facilities.value = response.data
    })

    return {
      tab,
      tabs,
      option,
      dataId,
      saveData,
      postData,
      putData,
      facilities,
      pageData,
      filterData,

      changeTab,
      onSave,
      onCancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
